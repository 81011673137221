import { useEffect, useState } from 'react';
import { AXAnalyticsService } from 'Roblox';
import experimentationConstants from '../../../itemDetailsThumbnail/constants/experimentConstants';
import useExperimentationService from '../avatarEnrollments/experimentationService';

const { AXAnalyticsConstants } = AXAnalyticsService;

interface ExperimentationResponse {
  [parameter: string]: unknown;
}

interface ExperimentationData {
  refreshEnabled?: boolean;
  robuxInThumbnail?: boolean;
}

export default function useExperimentCatalogRevamp(): {
  catalogRevampEnabled: boolean;
  robuxInThumbnailEnabled: boolean;
} {
  const experimentationService = useExperimentationService();
  const [catalogRevampEnabled, setCatalogRevampEnabled] = useState(false);
  const [robuxInThumbnailEnabled, setRobuxInThumbnailEnabled] = useState(false);

  useEffect(() => {
    experimentationService
      .getABTestEnrollment(
        experimentationConstants.defaultProjectId,
        experimentationConstants.layerNames.avatarExperienceCatalog,
        experimentationConstants.parameterNames.marketplaceRevamp
      )
      .then((response: ExperimentationResponse) => {
        const data = response as ExperimentationData;
        setCatalogRevampEnabled(data.refreshEnabled ?? false);
        setRobuxInThumbnailEnabled(data.robuxInThumbnail ?? false);
        // Sent counters to determine if user is in the experiment
        if (data.refreshEnabled) {
          if (data.robuxInThumbnail) {
            AXAnalyticsService.sendAXTracking({
              counterName: AXAnalyticsConstants.CatalogRevampEnabledWithoutRobuxInThumbnail,
              itemName: AXAnalyticsConstants.CatalogRevampEnabledWithRobuxInThumbnail,
              excludeTelemetry: true
            });
          } else {
            AXAnalyticsService.sendAXTracking({
              counterName: AXAnalyticsConstants.CatalogRevampEnabledWithoutRobuxInThumbnail,
              itemName: AXAnalyticsConstants.CatalogRevampEnabledWithoutRobuxInThumbnail,
              excludeTelemetry: true
            });
          }
        } else if (!data.refreshEnabled) {
          AXAnalyticsService.sendAXTracking({
            counterName: AXAnalyticsConstants.CatalogRevampEnabledWithoutRobuxInThumbnail,
            itemName: AXAnalyticsConstants.CatalogRevampControlGroup,
            excludeTelemetry: true
          });
        }
      })
      .catch(error => console.error('Error fetching experimentation values:', error));
  }, []); // explicitly dont add experimentationService to dependecies - causes strange multiple requests

  return { catalogRevampEnabled, robuxInThumbnailEnabled };
}

import React, { useCallback } from 'react';
import { ItemCard, ItemCardUtils, ShoppingCartProps } from 'react-style-guide';
import {
  DefaultThumbnailSize,
  Thumbnail2d,
  ThumbnailAssetsSize,
  ThumbnailTypes
} from 'roblox-thumbnails';
import { BadgeSizes, VerifiedBadgeIconContainer } from 'roblox-badges';
import classNames from 'classnames';
import { WithTranslationsProps, withTranslations } from 'react-utilities';
import ShimmerContainer from '../../shimmerContainer/ShimmerContainer';
import { Layout, ItemWithDetails } from '../../../constants/types';
import useShoppingCart from '../../../../shoppingCart/hooks/useShoppingCart';
import { TAssetItemDetails } from '../../../../itemDetailsInfo/constants/types';
import { translationConfig } from '../../../translation.config';

export type ItemResultsProps = {
  layout: Layout;
  isPaginationEnabled: boolean;
  enableThumbnailPrice?: boolean;
  enableCatalogRevampExperiment?: boolean;
  orderedItems: ItemWithDetails[] | undefined;
  showShimmer: boolean;
  numberOfItemsToDisplay: number;
};

function ItemResults(props: ItemResultsProps & WithTranslationsProps): JSX.Element {
  const {
    layout,
    isPaginationEnabled,
    translate,
    enableThumbnailPrice,
    enableCatalogRevampExperiment,
    orderedItems,
    showShimmer,
    numberOfItemsToDisplay
  } = props;

  const { isItemInCart, addItemToCart, removeItemFromCart } = useShoppingCart();

  const showError = layout.searchItemsError && !layout.loading;

  const renderError = useCallback(() => {
    return (
      <div className='section-content-off'>
        {translate(
          layout.searchItemsError === 'no_results'
            ? 'Response.NoItemsFound'
            : 'Response.TemporarilyUnavailable'
        )}
      </div>
    );
  }, [layout.searchItemsError, translate]);

  const renderItemCards = useCallback(() => {
    return (
      <React.Fragment>
        {/* Item Cards */}
        <ul
          className={classNames('hlist item-cards-stackable', {
            faded: !isPaginationEnabled && layout.loading,
            'organic-items-wrapper': enableCatalogRevampExperiment
          })}>
          {orderedItems?.map(item => {
            const shoppingCartProps: ShoppingCartProps = {
              isItemInCart: isItemInCart(item.id),
              addItemToCart,
              removeItemFromCart
            };

            return (
              <ItemCard
                key={item.id}
                id={item.id}
                name={item.name}
                type={item.itemType}
                creatorName={item.creatorName}
                creatorType={item.creatorType}
                creatorTargetId={item.creatorTargetId}
                price={item.price}
                lowestPrice={item.lowestPrice}
                unitsAvailableForConsumption={item.unitsAvailableForConsumption}
                itemStatus={(item as TAssetItemDetails).itemStatus}
                priceStatus={item.priceStatus}
                premiumPricing={item.premiumPricing?.premiumPriceInRobux}
                itemRestrictions={item.itemRestrictions}
                thumbnail2d={
                  <Thumbnail2d
                    type={
                      ItemCardUtils.checkIfBundle(item.itemType)
                        ? ThumbnailTypes.bundleThumbnail
                        : ThumbnailTypes.assetThumbnail
                    }
                    targetId={item.id}
                    size={
                      enableCatalogRevampExperiment
                        ? ThumbnailAssetsSize.size420
                        : DefaultThumbnailSize
                    }
                  />
                }
                iconToRender={
                  item.creatorHasVerifiedBadge ? (
                    <VerifiedBadgeIconContainer
                      overrideImgClass='verified-badge-icon-catalog-item-rendered'
                      size={BadgeSizes.TITLE}
                      titleText={item.creatorTargetId.toString()}
                    />
                  ) : undefined
                }
                shoppingCartProps={shoppingCartProps}
                containerClassName={
                  enableCatalogRevampExperiment ? 'catalog-item-container' : undefined
                }
                enableThumbnailPrice={enableThumbnailPrice}
              />
            );
          })}
        </ul>
      </React.Fragment>
    );
  }, [
    addItemToCart,
    enableCatalogRevampExperiment,
    enableThumbnailPrice,
    isItemInCart,
    isPaginationEnabled,
    layout.loading,
    orderedItems,
    removeItemFromCart
  ]);

  return (
    <React.Fragment>
      {showError ? renderError() : renderItemCards()}

      {/* Spinners and Shimmer Effects */}
      {layout.initialized && layout.loading && <div className='spinner spinner-sm' />}
      {showShimmer && (
        <ShimmerContainer
          numberOfItemsToDisplay={numberOfItemsToDisplay}
          shimmerCardClassName={
            enableCatalogRevampExperiment ? 'catalog-item-container' : undefined
          }
        />
      )}
    </React.Fragment>
  );
}

export default withTranslations(ItemResults, translationConfig);
